<template>
  <div class="h-100 f-center">
    <div>
      <Loader />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Loader from '@/components/Loader/Loader.vue';
import AuthService from '@/services/auth.service';

export default {
  name: 'ConfirmEmail',
  components: { Loader },
  mounted() {
    AuthService.confirmEmail()
      .then(async () => {
        await this.$router.push('/').catch(() => {});
        setTimeout(this.successfullyToast, 1500);
      })
      .catch((error) => {
        if (error.response?.data?.error === 'invalid token') {
          this.warningToast(
            this.$t('errorMessages.invalidToken'),
            this.$t('supportText.beforeTryLogin'),
            true,
          );
        } else {
          this.warningToast();
        }
      });
  },
  methods: {
    ...mapActions('toaster', ['setToaster']),
    successfullyToast() {
      const toast = {
        title: this.$t('supportText.emailIsConfirmed'),
        body: null,
      };
      this.setToaster({
        type: 'toast-success',
        toast,
      });
    },
    warningToast(
      title = this.$t('errorMessages.somethingWentWrong'),
      body = this.$t('supportText.tryRefreshPage'),
    ) {
      const toast = {
        title,
        body,
        button: {
          action: () => this.$router.push({ name: 'Authorization' }, () => {}),
          text: this.$t('buttonLabels.login'),
        },
      };
      this.setToaster({
        type: 'toast-warning',
        toast,
      });
    },
  },
};
</script>
